
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
	name: 'MobileDropdown'
})
export default class MobileDropdown extends Vue {
	@Prop({
		type: String,
		default: 'user',
		validator: (value: string) => ['user', 'company'].includes(value)
	})
	readonly styling!: string;

	@Prop({ type: Boolean, default: null }) readonly loggedIn!: boolean;

	@Prop({ type: Boolean, default: true }) readonly showLogin!: boolean;

	@Prop({ type: Array }) readonly categories!: { name: string; alias: string }[];

	state = this.styling;

	showMagazineDropdown = false;

	handleLink(link: string) {
		if (link !== '/karriere') {
			this.$emit('click');
		}
		this.$trackWebsiteEvent('click_menu', { url: link });
	}

	handleCategoryLink(event, alias: string) {
		event?.preventDefault();
		this.$emit('click');
		this.$router.push(alias);
	}

	get userLinks() {
		return [
			{
				name: 'Jobsuche',
				url: '/jobs'
			},
			{
				name: 'Lebenslauf',
				url: '/lebenslauf-erstellen'
			},
			{
				name: 'Brutto Netto Rechner',
				url: '/lp/brutto-netto-rechner'
			},
			{
				name: 'Karriere-Tipps',
				url: '/karriere'
			},
			{
				name: 'Für Arbeitgeber',
				url: '/business'
			}
		];
	}

	get companyLinks() {
		return [
			{
				name: 'Produkte',
				url: '/business/products'
			},
			{
				name: 'Preise',
				url: '/business/pricing'
			},
			{
				name: 'Guide',
				url: '/business/guide'
			},
			{
				name: 'HR-Tipps',
				url: '/business/hr-tipps'
			}
		];
	}
}
