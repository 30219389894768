import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { ICompanyState } from '~/store/company/state';
import type { IRecruiterTrackingOptions } from '@hokify/tracking/types';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;

export const actions: ActionTree<ICompanyState, IRootState> = {
	async loadTopCompanies(
		{ commit },
		{ page, limit }: { page?: number; limit?: number } = {}
	): Promise<number> {
		const result = await this.$axios.$get('/website-api/companies/list', {
			params: {
				limit,
				page
			}
		});

		commit('updateTopCompanies', result.companies);
		return result.companies?.length || 0;
	},
	async loadOtherCompanies(
		{ commit },
		{ page, limit }: { page?: number; limit?: number } = {}
	): Promise<number> {
		const result = await this.$axios.$get(`/website-api/companies/others`, {
			params: {
				limit,
				page
			}
		});

		commit('updateOtherCompanies', result.companies);
		return result.companies?.length || 0;
	},
	recruiterTracking(_, params: IRecruiterTrackingOptions) {
		this.$axios.$post('/app-api/recruiter/tracking/track-action', params, { baseURL });
	}
};

export default actions;
