<template functional>
	<nav class="flex-1 flex items-center justify-between ml-10">
		<div class="flex items-center">
			<HokLink
				to="/business/products"
				active-class="border-color-main-light"
				class="header-link border-transparent"
				link-type="internal"
				>Produkte</HokLink
			>
			<HokLink
				to="/business/pricing"
				active-class="border-color-main-light"
				class="header-link border-transparent"
				link-type="internal"
				>Preise</HokLink
			>
			<HokLink
				to="/business/hr-tipps"
				active-class="border-color-main-light"
				class="header-link border-transparent"
				link-type="internal"
				>HR-Tipps</HokLink
			>
		</div>
		<div class="flex items-center space-x-3 lg:space-x-6 pr-3">
			<div class="py-1" @click="parent.handleSupportRequest">
				<p
					class="hidden xl:block text-xs font-bold text-color-white cursor-pointer hover:underline mb-0"
				>
					Kostenlose Beratung
					<br />
					{{ parent.supportPhone }}
				</p>
			</div>
			<div v-if="props.loggedIn && props.user" class="group relative inline-block">
				<HokLink
					:to="
						props.user.mode && props.user.mode === 'jobseeker' ? '/user-profile' : '/app/company'
					"
					:aria-label="
						props.user.mode && props.user.mode === 'jobseeker' ? 'Zum Profil' : 'Zum Businessprofil'
					"
					link-type="external"
					class="flex items-center space-x-2 py-3"
				>
					<HokImg
						v-if="props.user.images && props.user.images.small"
						:source="props.user.images"
						size="small"
						ssr
						class="rounded-full object-cover w-8 h-8"
					/>
					<HokIcon v-else :size="8" color="white" icon="icon-profile" />
					<span class="font-bold text-color-white">
						{{ (props.user.general && props.user.general.firstName) || props.user.displayName }}
					</span>
					<HokIcon :size="4" color="white" icon="icon-arrow-down" />
				</HokLink>
				<ul
					class="group-hover:block hidden absolute bg-color-white border-t-4 border-color-main-business rounded-b-lg shadow-lg py-2"
				>
					<li>
						<HokLink
							:to="
								props.user.mode && props.user.mode === 'jobseeker'
									? '/user-profile'
									: '/app/company'
							"
							class="text-color-text hover:text-color-main-business px-3 py-1"
							link-type="external"
							>Profil</HokLink
						>
					</li>
					<li
						class="px-3 py-1 hover:text-color-main-business cursor-pointer"
						@click="listeners.logout"
					>
						Abmelden
					</li>
				</ul>
			</div>

			<HokButton v-else link-type="external" to="/app/company?origin=website_navbar" color="white">
				Anmelden
			</HokButton>

			<HokLink to="/pwa/company/checkout?origin=website_navbar" link-type="external">
				<HokIcon icon="icon-cart" color="white" />
			</HokLink>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'HeaderNavDesktopCompany',
	props: {
		loggedIn: { type: Boolean, required: false, default: null },
		user: { type: Object, required: false, default: null }
	}
};
</script>
