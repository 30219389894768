import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IBusinessState } from '~/store/business/state';

export const actions: ActionTree<IBusinessState, IRootState> = {
	async loadFaqSectionList({ state, commit }) {
		const faqData = await this.$axios.$get(`/website-api/help/business/faq`);
		commit('updateFaqSectionList', faqData.faq);

		return state.faqSectionList;
	},
	async loadTutorialSectionList({ state, commit }) {
		const tutorialData = await this.$axios.$get(`/website-api/help/business/tutorials`);
		commit('updateTutorialSectionList', tutorialData.tutorials);

		return state.tutorialSectionList;
	},
	async loadLandingPageData({ commit }) {
		const landingPageDate = await this.$axios.$get(`/website-api/business/business-landing-page`);
		commit('updateLandingPageData', landingPageDate);
	},
	async sendInviteCode(_, inviteCode: string) {
		const result = await this.$axios.$get(`/website-api/business/invite/${inviteCode}`);
		return result;
	},
	async sendSupportInquiry(
		_,
		payload: { message: string; recaptchaToken?: string; recaptchaVersion?: 'v2' | 'v3' }
	) {
		const result = await this.$axios.$post('/website-api/general/send-support-inquiry', payload);
		return result;
	}
};

export default actions;
